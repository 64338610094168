import React, { Component } from 'react';

class BreadcrumbTwo extends Component {
    render() {
        return (
            <section className="section breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 className="text-white text-uppercase mb-3">{this.props.heading}</h2>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item"><a className="text-uppercase text-white" href="/">{this.props.home}</a></li>
                                    <li className="breadcrumb-item"><a className="text-uppercase text-white" href="/#">{this.props.page}</a></li>
                                    <li className="breadcrumb-item"><a className="text-uppercase text-white" href="/#">{this.props.subpage}</a></li>
                                    <li className="breadcrumb-item text-white active">{this.props.title}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BreadcrumbTwo;