import React, { Component } from 'react';

class HeaderOne extends Component {
    render() {
        return (
            <header id="header">
                {/* Navbar */}
                <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                    <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-regular" src={this.props.imageData} alt="brand-logo" />
                        <img className="navbar-brand-sticky" src="/img/logo.png" alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                        {/* Navbar */}
                        <ul className="navbar-nav items">
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/#">Home <i className="fas fa-angle-down ml-1" /></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="/#">Multi-Page <i className="fas fa-angle-right ml-1" /></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="/" className="nav-link">Homepage 1</a></li>
                                            <li className="nav-item"><a href="/theme-two" className="nav-link">Homepage 2</a></li>
                                            <li className="nav-item"><a href="/theme-three" className="nav-link">Homepage 3</a></li>
                                            <li className="nav-item"><a href="/theme-four" className="nav-link">Homepage 4</a></li>
                                            <li className="nav-item"><a href="/theme-five" className="nav-link">Homepage 5</a></li>
                                            <li className="nav-item"><a href="/theme-six" className="nav-link">Homepage 6</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="/#">One-Page <i className="fas fa-angle-right ml-1" /></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="/theme-seven" className="nav-link">Homepage 1</a></li>
                                            <li className="nav-item"><a href="/theme-eight" className="nav-link">Homepage 2</a></li>
                                            <li className="nav-item"><a href="/theme-nine" className="nav-link">Homepage 3</a></li>
                                            <li className="nav-item"><a href="/theme-ten" className="nav-link">Homepage 4</a></li>
                                            <li className="nav-item"><a href="/theme-eleven" className="nav-link">Homepage 5</a></li>
                                            <li className="nav-item"><a href="/theme-twelve" className="nav-link">Homepage 6</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/about" className="nav-link">About</a>
                            </li>
                            <li className="nav-item">
                                <a href="/services" className="nav-link">Services</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/#">Pages <i className="fas fa-angle-down ml-1" /></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a href="/about" className="nav-link">About Us</a></li>
                                    <li className="nav-item"><a href="/services" className="nav-link">Our Services</a></li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="/#">Our Portfolio <i className="fas fa-angle-right ml-1" /></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="/portfolio-grid" className="nav-link">Portfolio Grid</a></li>
                                            <li className="nav-item"><a href="/portfolio-minimal" className="nav-link">Portfolio Minimal</a></li>
                                            <li className="nav-item"><a href="/portfolio-no-gap" className="nav-link">Portfolio No Gap</a></li>
                                            <li className="nav-item"><a href="/portfolio-masonry" className="nav-link">Portfolio Masonry</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><a href="/team" className="nav-link">Our Team</a></li>
                                    <li className="nav-item"><a href="/pricing" className="nav-link">Pricing</a></li>
                                    <li className="nav-item"><a href="/contact" className="nav-link">Contact</a></li>
                                    <li className="nav-item"><a href="/404" className="nav-link">404</a></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="/#">Blog Pages <i className="fas fa-angle-down ml-1" /></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="/#">Blog Grid <i className="fas fa-angle-right ml-1" /></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="/blog-two-column" className="nav-link">Grid 2 Column</a></li>
                                            <li className="nav-item"><a href="/blog-three-column" className="nav-link">Grid 3 Column</a></li>
                                            <li className="nav-item"><a href="/blog-left-sidebar" className="nav-link">Left Sidebar</a></li>
                                            <li className="nav-item"><a href="/blog-right-sidebar" className="nav-link">Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="/#">Blog Single <i className="fas fa-angle-right ml-1" /></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a href="/blog-details-left-sidebar" className="nav-link">Single Left Sidebar</a></li>
                                            <li className="nav-item"><a href="/blog-details-right-sidebar" className="nav-link">Single Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        {/* Navbar Icons */}
                        <ul className="navbar-nav icons">
                            <li className="nav-item">
                                <a href="/#" className="nav-link" data-toggle="modal" data-target="#search">
                                    <i className="fas fa-search" />
                                </a>
                            </li>
                            <li className="nav-item social">
                                <a href="/#" className="nav-link"><i className="fab fa-facebook-f" /></a>
                            </li>
                            <li className="nav-item social">
                                <a href="/#" className="nav-link"><i className="fab fa-twitter" /></a>
                            </li>
                        </ul>
                        {/* Navbar Toggler */}
                        <ul className="navbar-nav toggle">
                            <li className="nav-item">
                                <a href="/#" className="nav-link" data-toggle="modal" data-target="#menu">
                                    <i className="fas fa-bars toggle-icon m-0" />
                                </a>
                            </li>
                        </ul>
                        {/* Navbar Action Button */}
                        <ul className="navbar-nav action">
                            <li className="nav-item ml-3">
                                <a href="/contact" className="btn ml-lg-auto btn-bordered-white"><i className="fas fa-paper-plane contact-icon mr-md-2" />Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default HeaderOne;