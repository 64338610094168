import React, { Component } from 'react';

const initData = {
    btnText: "View More"
}

const data = [
    {
        id: 1,
        image: "/img/blog_1.jpg",
        title: "Digital Marketing",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 2,
        image: "/img/blog_2.jpg",
        title: "App Development",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 3,
        image: "/img/blog_3.jpg",
        title: "Data Analysis",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 4,
        image: "/img/blog_4.jpg",
        title: "Content Management",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 5,
        image: "/img/blog_5.jpg",
        title: "SEO Marketing",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 6,
        image: "/img/blog_6.jpg",
        title: "Marketing Strategy",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 7,
        image: "/img/blog_7.jpg",
        title: "Data Analysis",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 8,
        image: "/img/blog_8.jpg",
        title: "Digital Marketing",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    },
    {
        id: 9,
        image: "/img/blog_9.jpg",
        title: "Content Management",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit."
    }
]

class PortfolioFive extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="portfolio" className="portfolio-area style-two bg-gray overflow-hidden ptb_100">
                <div className="row justify-content-center">
                    {this.state.data.map((item, idx) => {
                        return(
                            <div key={`pf_${idx}`} className="col-12 col-sm-6 col-lg-4 p-0">
                                {/* Single Case Studies */}
                                <div className="single-case-studies mb-0">
                                    {/* Case Studies Thumb */}
                                    <a href="/pricing">
                                        <img src={item.image} alt="" />
                                    </a>
                                    {/* Case Studies Overlay */}
                                    <a href="/pricing" className="case-studies-overlay">
                                        {/* Overlay Text */}
                                        <span className="overlay-text text-center p-3">
                                            <h3 className="text-white mb-3">{item.title}</h3>
                                            <p className="text-white">{item.text}</p>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="row justify-content-center">
                    <a href="/pricing" className="btn btn-bordered mt-5">{this.state.initData.btnText}</a>
                </div>
            </section>
        );
    }
}

export default PortfolioFive;