import React, { Component } from 'react';

const initData = {
    heading: "We make the path to your digital presence",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    btnText_1: "Work with Us",
    btnText_2: "View Process"
}

class HeroTwo extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7">
                            <div className="welcome-intro">
                                <h1>{this.state.data.heading}</h1>
                                <p className="my-4">{this.state.data.content}</p>
                                {/* Buttons */}
                                <div className="button-group">
                                    <a href="/contact" className="btn btn-bordered">{this.state.data.btnText_1}</a>
                                    <a href="/pricing" className="btn btn-bordered d-none d-sm-inline-block">{this.state.data.btnText_2}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroTwo;