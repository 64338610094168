import React, { Component } from 'react';

const initData = {
    heading: "Customers loves us",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    btnText: "Work with Us"
}

const data = [
    {
        id: 1,
        quote: "/img/quote.png",
        avatar: "/img/avatar_1.png",
        reviewer: "Junaid Hasan",
        post: "CEO, Themeland",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam est modi amet error earum aperiam, fuga labore facere rem ab nemo possimus cum excepturi expedita. Culpa rerum, quaerat qui non."
    },
    {
        id: 2,
        quote: "/img/quote.png",
        avatar: "/img/avatar_2.png",
        reviewer: "Yasmin Akter",
        post: "Founder, Themeland",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam est modi amet error earum aperiam, fuga labore facere rem ab nemo possimus cum excepturi expedita. Culpa rerum, quaerat qui non."
    }
]

class ReviewThree extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="section review-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-4">
                            {/* Section Heading */}
                            <div className="section-heading m-0">
                                <h2 className="text-white">{this.state.initData.heading}</h2>
                                <p className="text-white d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="text-white d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                                <a href="/contact" className="btn btn-bordered-white mt-4">{this.state.initData.btnText}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row mt-5 mt-lg-0">
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <div key={`rt_${idx}`} className="col-12 col-md-6 res-margin">
                                            {/* Single Review */}
                                            <div className="single-review bg-inherit dark-shadow p-5">
                                                {/* Review Content */}
                                                <div className="review-content">
                                                    {/* Review Text */}
                                                    <div className="review-text">
                                                        <p className="text-white-50">{item.text}</p>
                                                    </div>
                                                    {/* Quotation Icon */}
                                                    <div className="quot-icon">
                                                        <img className="avatar-sm" src={item.quote} alt="" />
                                                    </div>
                                                </div>
                                                {/* Reviewer */}
                                                <div className="reviewer media mt-3">
                                                    {/* Reviewer Thumb */}
                                                    <div className="reviewer-thumb">
                                                        <img className="avatar-lg radius-100" src={item.avatar} alt="" />
                                                    </div>
                                                    {/* Reviewer Media */}
                                                    <div className="reviewer-meta media-body align-self-center ml-4">
                                                        <h5 className="reviewer-name text-white mb-2">{item.reviewer}</h5>
                                                        <h6 className="text-white-50 fw-6">{item.post}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewThree;