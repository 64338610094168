import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneTeamSection";

class TeamTwo extends Component {
    state = {
        teamData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    teamData: res.data.teamData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section team-area ptb_100">
                <div className="container">
                    <div className="row">
                        {this.state.teamData.map((item, idx) => {
                            return(
                                <div key={`to_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Team */}
                                    <div className="single-team">
                                        {/* Team Photo */}
                                        <div className="team-photo">
                                            <a href="/#"><img src={item.image} alt="" /></a>
                                        </div>
                                        {/* Team Content */}
                                        <div className="team-content p-3">
                                            <a href="/#">
                                                <h4 className="mb-2">{item.name}</h4>
                                            </a>
                                            <h6 className="text-uppercase fw-5">{item.post}</h6>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default TeamTwo;