import React, { Component } from 'react';

const initData = {
    heading: "Our Goal",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis tenetur maxime labore recusandae enim dolore, nesciunt, porro molestias ullam eum atque harum! Consectetur, facilis maxime ratione fugiat laborum omnis atque quae, molestiae rem perspiciatis veritatis cumque ex minima, numquam quis dicta impedit possimus tempore? Quo sequi labore, explicabo sit vitae.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto iure excepturi eos, tenetur minima dignissimos repellendus laudantium, rem, quo ipsam esse maiores sequi ex debitis quae facilis dolorum voluptates animi.",
    image: "/img/about_thumb_2.jpg",
    btnText: "Read More"
}

class Goal extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section our-goal ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* Goal Content */}
                            <div className="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                                <h2 className="mb-3">{this.state.data.heading}</h2>
                                <p>{this.state.data.headingText_1}</p><br />
                                <p>{this.state.data.headingText_2}</p>
                                <a href="/#" className="btn btn-bordered mt-4">{this.state.data.btnText}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Goal Thumb */}
                            <div className="goal-thumb mt-5 mt-lg-0">
                                <img src={this.state.data.image} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Goal;