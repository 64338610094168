import React, { Component } from 'react';

const initData = {
    image_1: "/img/blog_1.jpg",
    image_2: "/img/blog_2.jpg",
    image_3: "/img/blog_3.jpg",
    image_4: "/img/blog_4.jpg",
    image_5: "/img/blog_5.jpg",
    image_6: "/img/blog_6.jpg",
    image_7: "/img/blog_7.jpg",
    image_8: "/img/blog_8.jpg",
    image_9: "/img/blog_9.jpg",
    title_1: "Digital Marketing",
    title_2: "App Development",
    title_3: "Content Management",
    title_4: "Data Analysis",
    title_5: "SEO Marketing",
    title_6: "Marketing Strategy",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
    btnText: "View More"
}

class PortfolioSix extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="portfolio" className="portfolio-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                            {/* Portfolio Menu */}
                            <div className="portfolio-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-4 mb-md-5" data-toggle="buttons">
                                <label className="btn active d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="all" defaultChecked className="portfolio-btn" />
                                    <span>All</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="marketing" className="portfolio-btn" />
                                    <span>Marketing</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="agency" className="portfolio-btn" />
                                    <span>Agency</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="seo" className="portfolio-btn" />
                                    <span>SEO</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="development" className="portfolio-btn" />
                                    <span>App Development</span>
                                </label>
                            </div>
                        </div>
                        </div>
                        {/* Portfolio Items */}
                        <div className="row items portfolio-items">
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;marketing&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_1} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_1}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;seo&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_2} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_2}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;marketing&quot;,&quot;agency&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_3} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_3}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;agency&quot;,&quot;development&quot;,&quot;seo&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_4} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_4}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;development&quot;,&quot;marketing&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_5} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_5}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;agency&quot;,&quot;development&quot;,&quot;marketing&quot;,&quot;seo&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_6} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_6}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;development&quot;,&quot;seo&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_7} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_4}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_8} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_1}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;marketing&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                            {/* Case Studies Thumb */}
                            <a href="/pricing">
                                <img src={this.state.initData.image_9} alt="" />
                            </a>
                            {/* Case Studies Overlay */}
                            <a href="/pricing" className="case-studies-overlay">
                                {/* Overlay Text */}
                                <span className="overlay-text text-center p-3">
                                <h3 className="text-white mb-3">{this.state.initData.title_3}</h3>
                                <p className="text-white">{this.state.initData.text}</p>
                                </span>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <a href="/pricing" className="btn btn-bordered mt-4">{this.state.initData.btnText}</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default PortfolioSix;