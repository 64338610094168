import React, { Component } from 'react';

const initData = {
    heading: "Our Recent Works",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    image_1: "/img/case_studies_1.jpg",
    image_2: "/img/case_studies_2.jpg",
    image_3: "/img/case_studies_3.jpg",
    image_4: "/img/case_studies_4.jpg",
    image_5: "/img/case_studies_5.jpg",
    image_6: "/img/case_studies_6.jpg",
    title_1: "Digital Marketing",
    title_2: "App Development",
    title_3: "Content Management",
    title_4: "Data Analysis",
    title_5: "SEO Marketing",
    title_6: "Marketing Strategy",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
    btnText: "View More"
}

class PortfolioOne extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="portfolio" className="portfolio-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                            {/* Portfolio Menu */}
                            <div className="portfolio-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-4 mb-md-5" data-toggle="buttons">
                                <label className="btn active d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="all" defaultChecked className="portfolio-btn" />
                                    <span>All</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="marketing" className="portfolio-btn" />
                                    <span>Marketing</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="agency" className="portfolio-btn" />
                                    <span>Agency</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="seo" className="portfolio-btn" />
                                    <span>SEO</span>
                                </label>
                                <label className="btn d-table text-uppercase p-2">
                                    <input type="radio" defaultValue="development" className="portfolio-btn" />
                                    <span>App Development</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* Portfolio Items */}
                    <div className="row items portfolio-items">
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;marketing&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_1} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_1} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_1}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;seo&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_2} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_2} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_2}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;marketing&quot;,&quot;agency&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_4} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_3} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_3}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;agency&quot;,&quot;development&quot;,&quot;seo&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_3} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_4} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_4}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;development&quot;,&quot;marketing&quot;,&quot;development&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_5} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_5} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_5}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 portfolio-item" data-groups="[&quot;agency&quot;,&quot;development&quot;,&quot;marketing&quot;,&quot;seo&quot;]">
                            {/* Single Case Studies */}
                            <div className="single-case-studies">
                                {/* Case Studies Thumb */}
                                <a href="/#">
                                    <img src={this.state.initData.image_6} alt="" />
                                </a>
                                {/* Case Studies Overlay */}
                                <a href={this.state.initData.image_6} data-fancybox="images" className="case-studies-overlay">
                                    {/* Overlay Text */}
                                    <span className="overlay-text text-center p-3">
                                        <h3 className="text-white mb-3">{this.state.initData.title_6}</h3>
                                        <p className="text-white">{this.state.initData.text}</p>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <a href="/portfolio-minimal" className="btn btn-bordered mt-4">{this.state.initData.btnText}</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default PortfolioOne;