import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneServiceSection";

class ServiceThree extends Component {
    state = {
        data: {},
        serviceData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    serviceData: res.data.serviceData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="service" className="section service-area bg-grey ptb_150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.serviceData.map((item, idx) => {
                            return(
                                <div key={`so_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service text-center p-4">
                                        <span className={item.iconClass} />
                                        <h3 className="my-3">{item.title}</h3>
                                        <p>{item.text}</p>
                                        <a className="service-btn mt-3" href="/pricing">{item.btnText}</a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceThree;