import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOneContentSection";

class ContentThree extends Component {
    state = {
        data: {},
        contentData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    contentData: res.data.contentData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section content-area bg-grey ptb_150">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Content Inner */}
                            <div className="content-inner text-center">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-3">
                                    <h2>{this.state.data.heading}</h2>
                                    <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                    <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                                </div>
                                {/* Content List */}
                                <ul className="content-list text-left">
                                    {/* Single Content List */}
                                    {this.state.contentData.map((item, idx) => {
                                        return(
                                            <li key={`co_${idx}`} className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-1"><i className={item.iconClass} /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span><b>{item.title}</b><br />{item.text}</span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <a href="/about" className="btn btn-bordered mt-4">{this.state.data.btnText}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Service Thumb */}
                            <div className="service-thumb mx-auto pt-4 pt-lg-0">
                                <img src={this.state.data.contentThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContentThree;