import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-3/themeOneBlogDetails";

class Comments extends Component {
    state = {
        data: {},
        commentsData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    commentsData: res.data.commentsData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div>
                <div className="blog-comments p-3">
                    {/* Admin */}
                    <div className="admin media py-4">
                        {/* Admin Thumb */}
                        <div className="admin-thumb avatar-lg">
                            <img className="rounded-circle" src={this.state.data.adminImage} alt="" />
                        </div>
                        {/* Admin Content */}
                        <div className="admin-content media-body pl-3 pl-sm-4">
                            <h4 className="admin-name mb-2"><a href="/#">{this.state.data.admin}</a></h4>
                            <p>{this.state.data.adminText}</p>
                        </div>
                    </div>
                    {/* Comments */}
                    <div className="comments mt-4 mb-3">
                        {/* Comments Title */}
                        <h3 className="comments-title text-uppercase text-left text-lg-right mb-3">{this.state.data.commentsTitle}</h3>
                        {/* Single Comments */}
                        {this.state.commentsData.map((item, idx) => {
                            return(
                                <div key={`cd_${idx}`} className="single-comments media p-4">
                                    {/* Comments Thumb */}
                                    <div className="comments-thumb avatar-lg">
                                        <img className="rounded-circle" src={item.image} alt="" />
                                    </div>
                                    {/* Comments Content */}
                                    <div className="comments-content media-body pl-3">
                                        <h5 className="d-flex mb-2">
                                            <a href="/blog-details-right-sidebar">{item.author}</a>
                                            <a href="/blog-details-right-sidebar" className="ml-auto">{item.replyText}</a>
                                        </h5>
                                        <p className="d-none d-lg-block">{item.text_1}</p>
                                        <p className="d-block d-lg-none">{item.text_2}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="blog-contact p-3">
                    {/* Contact Title */}
                    <h3 className="comments-title text-uppercase text-left text-lg-right mb-3">{this.state.data.commentsPost}</h3>
                    {/* Comment Box */}
                    <div className="contact-box comment-box">
                        <form method="POST" action="#">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="name" placeholder="Name" required="required" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" placeholder="Email" required="required" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-bordered mt-3" type="submit">{this.state.data.postText}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Comments;