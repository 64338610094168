import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-3/themeOneBlogSection";

class BlogSingleTwo extends Component {
    state = {
        blogData_1: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    blogData_1: res.data.blogData_1
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div className="row">
                {this.state.blogData_1.map((item, idx) => {
                    return(
                        <div key={`bdo_${idx}`} className="col-12 col-md-6 col-lg-4">
                            {/* Single Blog */}
                            <div className="single-blog res-margin">
                                {/* Blog Thumb */}
                                <div className="blog-thumb">
                                    <a href="/blog-details-right-sidebar"><img src={item.image} alt="" /></a>
                                </div>
                                {/* Blog Content */}
                                <div className="blog-content">
                                    {/* Meta Info */}
                                    <ul className="meta-info d-flex justify-content-between">
                                        <li>By <a href="/blog-details-right-sidebar">{item.author}</a></li>
                                        <li><a href="/blog-details-right-sidebar">{item.date}</a></li>
                                    </ul>
                                    {/* Blog Title */}
                                    <h3 className="blog-title my-3"><a href="/blog-details-right-sidebar">{item.title}</a></h3>
                                    <p>{item.text}</p>
                                    <a href="/blog-details-right-sidebar" className="blog-btn mt-3">{item.btnText}</a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default BlogSingleTwo;