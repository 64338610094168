import React, { Component } from 'react';

const initData = {
    heading: "Get your business on",
    heading_span: "Google Search",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    btnText_1: "Start a Project",
    btnText_2: "Contact Us"
}

const data = [
    {
        id: 1,
        spanClass: "welcome-thumb-1",
        imgClass: "d-block ml-auto",
        image: "/img/welcome_thumb_11.png"
    },
    {
        id: 2,
        spanClass: "welcome-thumb-2",
        imgClass: "welcome-animation d-block",
        image: "/img/welcome_thumb_12.png"
    }
]

class HeroFive extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7">
                            <div className="welcome-intro">
                                <h1 className="text-white"><span className="fw-4">{this.state.initData.heading}</span> {this.state.initData.heading_span}</h1>
                                <p className="text-white-50 my-4">{this.state.initData.content}</p>
                                {/* Buttons */}
                                <div className="button-group">
                                    <a href="/services" className="btn btn-bordered-white active">{this.state.initData.btnText_1}</a>
                                    <a href="/contact" className="btn btn-bordered-white d-none d-sm-inline-block">{this.state.initData.btnText_2}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            {/* Welcome Thumb */}
                            <div className="welcome-thumb-wrapper mt-5 mt-md-0">
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <span key={`hf_${idx}`} className={item.spanClass}>
                                            <img className={item.imgClass} src={item.image} alt="" />
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroFive;