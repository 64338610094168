import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ThemeTwo from "../themes/theme-two";
import ThemeThree from "../themes/theme-three";
import ThemeFour from "../themes/theme-four";
import ThemeFive from "../themes/theme-five";
import ThemeSix from "../themes/theme-six";
import ThemeSeven from "../themes/theme-seven";
import ThemeEight from "../themes/theme-eight";
import ThemeNine from "../themes/theme-nine";
import ThemeTen from "../themes/theme-ten";
import ThemeEleven from "../themes/theme-eleven";
import ThemeTwelve from "../themes/theme-twelve";
import About from "../components/InnerPages/About/About";
import Services from "../components/InnerPages/Services/Services";
import PortfolioGrid from "../components/InnerPages/Portfolio/PortfolioGrid/PortfolioGrid";
import PortfolioMinimal from "../components/InnerPages/Portfolio/PortfolioMinimal/PortfolioMinimal";
import PortfolioNoGap from "../components/InnerPages/Portfolio/PortfolioNoGap/PortfolioNoGap";
import PortfolioMasonry from "../components/InnerPages/Portfolio/PortfolioMasonry/PortfolioMasonry";
import Team from "../components/InnerPages/Team/Team";
import Pricing from "../components/InnerPages/Pricing/Pricing";
import Contact from "../components/InnerPages/Contact/Contact";
import Error from "../components/InnerPages/Error/Error";
import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route exact path="/theme-two" component={ThemeTwo} />
            <Route exact path="/theme-three" component={ThemeThree} />
            <Route exact path="/theme-four" component={ThemeFour} />
            <Route exact path="/theme-five" component={ThemeFive} />
            <Route exact path="/theme-six" component={ThemeSix} />
            <Route exact path="/theme-seven" component={ThemeSeven} />
            <Route exact path="/theme-eight" component={ThemeEight} />
            <Route exact path="/theme-nine" component={ThemeNine} />
            <Route exact path="/theme-ten" component={ThemeTen} />
            <Route exact path="/theme-eleven" component={ThemeEleven} />
            <Route exact path="/theme-twelve" component={ThemeTwelve} />
            <Route exact path="/about" component={About} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/portfolio-grid" component={PortfolioGrid} />
            <Route exact path="/portfolio-minimal" component={PortfolioMinimal} />
            <Route exact path="/portfolio-no-gap" component={PortfolioNoGap} />
            <Route exact path="/portfolio-masonry" component={PortfolioMasonry} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/404" component={Error} />
            <Route exact path="/blog-two-column" component={BlogTwoColumn} />
            <Route exact path="/blog-three-column" component={BlogThreeColumn} />
            <Route exact path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <Route exact path="/blog-right-sidebar" component={BlogRightSidebar} />
            <Route exact path="/blog-details-left-sidebar" component={BlogDetailsLeftSidebar} />
            <Route exact path="/blog-details-right-sidebar" component={BlogDetailsRightSidebar} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;