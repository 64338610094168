import React, { Component } from 'react';
import axios from 'axios';

import Comments from './Comments';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-3/themeOneBlogDetails";

class SingleTwo extends Component {
    state = {
        data: {},
        iconList: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    iconList: res.data.iconList
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <article className="single-blog-details">
                {/* Blog Thumb */}
                <div className="blog-thumb">
                    <img src={this.state.data.image_2} alt="" />
                </div>
                {/* Blog Content */}
                <div className="blog-content digimax-blog p-3">
                    {/* Meta Info */}
                    <div className="meta-info d-flex flex-wrap align-items-center py-2">
                        <ul>
                            <li className="d-inline-block p-2">By <a href="/blog-details-right-sidebar">{this.state.data.author_2}</a></li>
                            <li className="d-none d-md-inline-block p-2"><a href="/blog-details-right-sidebar">{this.state.data.date}</a></li>
                            <li className="d-inline-block p-2"><a href="/blog-details-right-sidebar">{this.state.data.comments}</a></li>
                        </ul>
                        {/* Blog Share */}
                        <div className="blog-share ml-auto d-none d-sm-block">
                            {/* Social Icons */}
                            <div className="social-icons d-flex justify-content-center">
                                {this.state.iconList.map((item, idx) => {
                                    return(
                                        <a key={`il_${idx}`} className={item.linkClass} href="/blog-details-right-sidebar">
                                            <i className={item.iconClass} />
                                            <i className={item.iconClass} />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {/* Blog Details */}
                    <div className="blog-details">
                        <h3 className="blog-title py-3"><a href="/blog-details-right-sidebar">{this.state.data.title}</a></h3>
                        <p className="d-none d-sm-block">{this.state.data.text_1}</p>
                        <p className="d-block d-sm-none">{this.state.data.text_2}</p>
                        <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                            <p className="font-italic d-none d-sm-block">{this.state.data.quoteText_1}</p>
                            <p className="font-italic d-block d-sm-none">{this.state.data.quoteText_2}</p>
                        </blockquote>
                        <p className="d-none d-sm-block">{this.state.data.text_3}</p>
                        <p className="d-block d-sm-none">{this.state.data.text_4}</p>
                    </div>
                </div>
                {/* Blog Comments */}
                <Comments />
            </article>
        );
    }
}

export default SingleTwo;