import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOnePricingSection";

const initData = {
    text_1: "Haven't find suitable answer?",
    text_2: "Tell us what you need."
}

const faqData = [
    {
        id: 1,
        title: "What is a digital agency?",
        text: "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
    },
    {
        id: 2,
        title: "How to start SEO business?",
        text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source."
    },
    {
        id: 3,
        title: "How to use marketing services?",
        text: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 4,
        title: "Is the Contact form active?",
        text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source."
    }
]

const faqData_1 = [
    {
        id: 1,
        title: "How can I use the portfolio?",
        text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
    },
    {
        id: 2,
        title: "What other services do you offer?",
        text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    },
    {
        id: 3,
        title: "Is the template well-documented?",
        text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    },
    {
        id: 4,
        title: "Can I get direct support from the author?",
        text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
    }
]

class PricingFour extends Component {
    state = {
        initData: {},
        pricingData: [],
        faqData: [],
        faqData_1: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    initData: initData,
                    pricingData: res.data.pricingData,
                    faqData: faqData,
                    faqData_1: faqData_1
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="pricing" className="section price-plan-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="row price-plan-wrapper">
                                {this.state.pricingData.map((item, idx) => {
                                    return(
                                        <div key={`pro_${idx}`} className={item.colClass}>
                                            {/* Single Price Plan */}
                                            <div className={item.linkClass}>
                                                {/* Plan Title */}
                                                <div className="plan-title mb-2 mb-sm-3">
                                                    <h3 className="mb-2">{item.title} <sup><span className="badge badge-pill badge-warning ml-2">{item.price_badge}</span></sup></h3>
                                                    <p>{item.text}</p>
                                                </div>
                                                {/* Plan Price */}
                                                <div className="plan-price pb-2 pb-sm-3">
                                                    <span className="color-primary fw-7">{item.price_before}</span>
                                                    <span className="h1 fw-7">{item.price}</span>
                                                    <sub className="validity text-muted fw-5">{item.price_after}</sub>
                                                </div>
                                                {/* Plan Description */}
                                                <div className="plan-description">
                                                    <ul className="plan-features">
                                                        <li className={item.listClass_1}>{item.li_1}</li>
                                                        <li className={item.listClass_1}>{item.li_2}</li>
                                                        <li className={item.listClass_2}>{item.li_3}</li>
                                                        <li className={item.listClass_2}>{item.li_4}</li>
                                                    </ul>
                                                </div>
                                                {/* Plan Button */}
                                                <div className="plan-button">
                                                    <a href="/contact" className="btn btn-bordered mt-3">{item.btnText}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-5">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            {/* Single Card */}
                                            {this.state.faqData.map((item, idx) => {
                                                return(
                                                    <div key={`fdo_${idx}`} className="card border-0">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="mb-0">
                                                                <button className="btn px-0 py-3" type="button">
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        {/* Card Body */}
                                                        <div className="card-body px-0 py-3">
                                                            {item.text}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {/* Single Card */}
                                            {this.state.faqData_1.map((item, idx) => {
                                                return(
                                                    <div key={`fdo_${idx}`} className="card border-0">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="mb-0">
                                                                <button className="btn px-0 py-3" type="button">
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        {/* Card Body */}
                                                        <div className="card-body px-0 py-3">
                                                            {item.text}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{this.state.initData.text_1} <a href="/contact">{this.state.initData.text_2}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingFour;