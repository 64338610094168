import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneServiceSection";

class ServiceSix extends Component {
    state = {
        data: {},
        serviceData_3: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    serviceData_3: res.data.serviceData_3
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section service-area ptb_150">
                <div className="container">
                    <div className="row">
                        {this.state.serviceData_3.map((item, idx) => {
                            return(
                                <div key={`ss_${idx}`} className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service service-gallery m-0 overflow-hidden">
                                        {/* Service Thumb */}
                                        <div className="service-thumb">
                                            <a href="/pricing"><img src={item.image} alt="" /></a>
                                        </div>
                                        {/* Service Content */}
                                        <div className="service-content bg-white">
                                            <a href="/pricing">
                                                <h3>{item.title}</h3>
                                            </a>
                                            <p className="py-3">{item.text}</p>
                                            <a className="service-btn" href="/pricing">{item.btnText}</a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSix;